<!--=====================================-->
<!--=  Coming Soon Section Area Start   =-->
<!--=====================================-->
<section class="coming-soon-wrap has-animation">
    <ul class="shape-holder has-animation">
        <li>
            <svg width="945px" height="945px">
                <path opacity="0.1" fill="rgb(47, 30, 134)"
                    d="M472.500,0.000 C733.454,0.000 945.000,211.546 945.000,472.500 C945.000,733.454 733.454,945.000 472.500,945.000 C211.545,945.000 -0.000,733.454 -0.000,472.500 C-0.000,211.546 211.545,0.000 472.500,0.000 Z" />
            </svg>
        </li>
        <li>
            <svg width="945px" height="945px">
                <path opacity="0.1" fill="rgb(47, 30, 134)"
                    d="M472.500,0.000 C733.454,0.000 945.000,211.546 945.000,472.500 C945.000,733.454 733.454,945.000 472.500,945.000 C211.545,945.000 -0.000,733.454 -0.000,472.500 C-0.000,211.546 211.545,0.000 472.500,0.000 Z" />
            </svg>
        </li>
        <li>
            <svg width="945px" height="945px">
                <path opacity="0.1" fill="rgb(47, 30, 134)"
                    d="M472.500,0.000 C733.454,0.000 945.000,211.546 945.000,472.500 C945.000,733.454 733.454,945.000 472.500,945.000 C211.545,945.000 -0.000,733.454 -0.000,472.500 C-0.000,211.546 211.545,0.000 472.500,0.000 Z" />
            </svg>
        </li>
        <li>
            <svg width="945px" height="945px">
                <path opacity="0.1" fill="rgb(47, 30, 134)"
                    d="M472.500,0.000 C733.454,0.000 945.000,211.546 945.000,472.500 C945.000,733.454 733.454,945.000 472.500,945.000 C211.545,945.000 -0.000,733.454 -0.000,472.500 C-0.000,211.546 211.545,0.000 472.500,0.000 Z" />
            </svg>
        </li>
        <li>
            <svg width="945px" height="945px">
                <path opacity="0.1" fill="rgb(47, 30, 134)"
                    d="M472.500,0.000 C733.454,0.000 945.000,211.546 945.000,472.500 C945.000,733.454 733.454,945.000 472.500,945.000 C211.545,945.000 -0.000,733.454 -0.000,472.500 C-0.000,211.546 211.545,0.000 472.500,0.000 Z" />
            </svg>
        </li>
        <li>
            <svg width="945px" height="945px">
                <path opacity="0.1" fill="rgb(47, 30, 134)"
                    d="M472.500,0.000 C733.454,0.000 945.000,211.546 945.000,472.500 C945.000,733.454 733.454,945.000 472.500,945.000 C211.545,945.000 -0.000,733.454 -0.000,472.500 C-0.000,211.546 211.545,0.000 472.500,0.000 Z" />
            </svg>
        </li>
        <li>
            <svg width="945px" height="945px">
                <path opacity="0.1" fill="rgb(47, 30, 134)"
                    d="M472.500,0.000 C733.454,0.000 945.000,211.546 945.000,472.500 C945.000,733.454 733.454,945.000 472.500,945.000 C211.545,945.000 -0.000,733.454 -0.000,472.500 C-0.000,211.546 211.545,0.000 472.500,0.000 Z" />
            </svg>
        </li>
    </ul>
    <div class="container">
        <div class="coming-soon-box">
            <div class="translate-zoomout-50 opacity-animation transition-150 transition-delay-100">
                <div id="countdown" class="countdown"></div>
            </div>
            <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-500">
                <div class="sub-title">Nós estamos chegando!!!</div>
            </div>
            <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1000">
                <h1 class="main-title">Estamos trabalhando em nosso website, fique atento!</h1>
            </div>
            <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1500">
                <a href="contato" class="item-btn btn-ghost btn-light">Notifique a gente</a>
            </div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=   Coming Soon Section Area End    =-->
<!--=====================================-->