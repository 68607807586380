import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consultoria-empresarial',
  templateUrl: './consultoria-empresarial.component.html',
  styleUrls: ['./consultoria-empresarial.component.css']
})
export class ConsultoriaEmpresarialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
