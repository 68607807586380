<!--=====================================-->
<!--=         Main Banner Start         =-->
<!--=====================================-->
<section id="page-banner" class="page-banner bg-gradient-layout6 has-animation">
    <ul class="shape-holder">
        <li>
            <div class="translate-top-50 opacity-animation transition-200 transition-delay-10">
                <svg width="827px" height="827px">
                    <defs>
                        <linearGradient id="shape1" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.6" />
                            <stop offset="100%" stop-color="rgb(117,14,213)" stop-opacity="0.6" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape1)"
                        d="M413.500,0.000 C641.870,0.000 827.000,185.130 827.000,413.500 C827.000,641.870 641.870,827.000 413.500,827.000 C185.130,827.000 -0.000,641.870 -0.000,413.500 C-0.000,185.130 185.130,0.000 413.500,0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-top-50 opacity-animation transition-100 transition-delay-300">
                <svg width="615px" height="615px">
                    <defs>
                        <linearGradient id="shape2" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.6" />
                            <stop offset="100%" stop-color="rgb(109,26,223)" stop-opacity="0.6" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape2)"
                        d="M307.500,0.000 C477.327,0.000 615.000,137.673 615.000,307.500 C615.000,477.327 477.327,615.000 307.500,615.000 C137.672,615.000 -0.000,477.327 -0.000,307.500 C-0.000,137.673 137.672,0.000 307.500,0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-top-50 opacity-animation transition-50 transition-delay-600">
                <svg width="441px" height="441px">
                    <defs>
                        <linearGradient id="shape3" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="13%" stop-color="rgb(82,63,255)" stop-opacity="0.4" />
                            <stop offset="100%" stop-color="rgb(130,33,221)" stop-opacity="0.4" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape3)"
                        d="M220.500,0.000 C342.279,0.000 441.000,98.721 441.000,220.500 C441.000,342.279 342.279,441.000 220.500,441.000 C98.721,441.000 -0.000,342.279 -0.000,220.500 C-0.000,98.721 98.721,0.000 220.500,0.000 Z" />
                </svg>
            </div>
        </li>
    </ul>
    <div class="container">
        <div class="breadcrumbs-area">
            <h1>Sobre nós</h1>
            <ul>
                <li>
                    <a href="">Início</a>
                </li>
                <li>Sobre nós</li>
            </ul>
        </div>
    </div>
</section>

<!--=====================================-->
<!--=           Main Banner End         =-->
<!--=====================================-->
<!--=====================================-->
<!--=     About Section Area Start      =-->
<!--=====================================-->
<section class="about-wrap-layout7">
    <ul class="animated-shape has-animation">
        <li>
            <div class="translate-right-75 opacity-animation transition-200 transition-delay-10">
                <img src="media/element/element17.png" alt="Animated Figure">
            </div>
        </li>
    </ul>
    <div class="container-fluid">
        <div class="row d-flex align-items-center">
            <div class="col-xl-6 col-12 pl-0 pr-0">
                <div class="about-box-layout7">
                    <ul class="figure-holder has-animation">
                        <li class="animated-bg">
                            <div class="translate-left-75 opacity-animation transition-200 transition-delay-10">
                                <svg width="638px" height="514px">
                                    <path fill-rule="evenodd" opacity="0.031" fill="rgb(2, 86, 225)"
                                        d="M256.191,-0.005 C397.682,-0.005 406.513,181.938 525.597,258.322 C824.664,450.157 454.262,521.729 256.191,512.196 C114.864,505.394 -0.000,397.536 -0.000,256.097 C-0.000,114.655 114.701,-0.005 256.191,-0.005 Z" />
                                </svg>
                            </div>
                        </li>
                        <li class="animated-figure">
                            <div class="translate-left-75 opacity-animation transition-150 transition-delay-1000">
                                <img src="media/about/about2.png" alt="About">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-6 col-12 compress-right-side">
                <div class="about-box-layout7">
                    <div class="content-holder has-animation">
                        <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1000">
                            <h2 class="item-title">Somos uma startup de Tecnologia localizada no Sudoeste Baiano. Fornececemos a solução perfeita para o seu négocio.</h2>
                        </div> 
                        <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1300">
                            <p>Temos a graça de ser a empresa pioneira de Tecnologia da Informação em nossa cidade, Pindaí-BA. Alguns serviços são feitos apenas no Sudoeste Baiano, mas conseguimos atender outros serviços para todo o Brasil e mundo.
                            </p>
                            <p>Trabalhamos com diversos tipos de empresas: pequena, média e grande. Nós oferecemos aos nossos clientes
                                soluções tecnológicas para impulsionar o seu negócio. Temos um suporte técnico disponível para você 24 horas, 7 dias por semana. Atendemos o seu requerimento o mais breve possível.</p>
                        </div>
                        <div class="row mt--50">
                            <div class="col-md-4 col-12">
                                <div
                                    class="translate-zoomout-50 opacity-animation transition-150 transition-delay-1800">
                                    <div class="progress-box-layout4">
                                        <h2 class="counting-text counter" data-num="20">20</h2>
                                        <div class="item-label">Clientes Felizes</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-12">
                                <div
                                    class="translate-zoomout-50 opacity-animation transition-150 transition-delay-2000">
                                    <div class="progress-box-layout4">
                                        <h2 class="counting-text counter" data-num="25">25</h2>
                                        <div class="item-label">Projetos Feitos</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-12">
                                <div
                                    class="translate-zoomout-50 opacity-animation transition-150 transition-delay-2200">
                                    <div class="progress-box-layout4">
                                        <h2 class="counting-text counter" data-num="3650">3650</h2>
                                        <div class="item-label">Dias de Trabalho</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=      About Section Area End       =-->
<!--=====================================-->
<!--=====================================-->
<!--=     About Section Area Start      =-->
<!--=====================================-->
<section class="about-wrap-layout8">
    <ul class="animated-buble has-animation">
        <li>
            <div class="translate-right-100 opacity-animation transition-200 transition-delay-100">
                <svg width="616px" height="616px">
                    <defs>
                        <linearGradient id="animated-buble1" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(90,73,248)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(123,100,242)" stop-opacity="1" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#animated-buble1)" opacity="0.4"
                        d="M308.000,-0.000 C478.104,-0.000 616.000,137.896 616.000,308.000 C616.000,478.103 478.104,616.000 308.000,616.000 C137.896,616.000 -0.000,478.103 -0.000,308.000 C-0.000,137.896 137.896,-0.000 308.000,-0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-right-100 opacity-animation transition-200 transition-delay-500">
                <svg width="489px" height="489px">
                    <defs>
                        <linearGradient id="animated-buble2" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(90,73,248)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(123,100,242)" stop-opacity="1" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#animated-buble2)" opacity="0.4"
                        d="M244.500,-0.000 C379.534,-0.000 489.000,109.466 489.000,244.500 C489.000,379.533 379.534,489.000 244.500,489.000 C109.466,489.000 -0.000,379.533 -0.000,244.500 C-0.000,109.466 109.466,-0.000 244.500,-0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-right-100 opacity-animation transition-200 transition-delay-900">
                <svg width="411px" height="411px">
                    <defs>
                        <linearGradient id="animated-buble3" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(90,73,248)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(123,100,242)" stop-opacity="1" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#animated-buble3)" opacity="0.4"
                        d="M205.500,-0.000 C318.994,-0.000 411.000,92.005 411.000,205.500 C411.000,318.994 318.994,411.000 205.500,411.000 C92.005,411.000 -0.000,318.994 -0.000,205.500 C-0.000,92.005 92.005,-0.000 205.500,-0.000 Z" />
                </svg>
            </div>
        </li>
    </ul>
    <div class="container-fluid">
        <div class="row d-flex align-items-center">
            <div class="col-xl-6 col-12 pl-0 pr-0">
                <div class="about-box-layout8">
                    <div class="figure-holder">
                        <img src="media/about/about-back.jpg" alt="About">
                        <a class="play-btn popup-youtube" href="https://www.youtube.com/watch?v=7CFRHnquObY">
                            <div class="item-icon"><i class="fas fa-play"></i></div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-12 compress-right-side">
                <div class="about-box-layout8">
                    <div class="content-holder has-animation">
                        <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1000">
                            <h2 class="item-title">Temos mais de 10 anos de experiência</h2>
                        </div>
                        <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1300">
                            <p>Fazemos o que fazemos por paixão. Temos uma vasta experiência para melhor te atender. Ofereçemos muitos serviços para não deixar o nosso cliente na mão.</p>
                        </div>
                        <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1600">
                            <ul class="list-item">
                                <li>Desenvolvimento de Sistemas/Sites Customizados</li>
                                <li>Consultoria Empresarial</li>
                                <li>Consultoria Estratégica</li>
                                <li>Comercialização de Sistemas PDV</li>
                                <li>Comercialização de Sistemas de Gestão (ERP)</li>
                                <li>Emissão de Certificado Digital</li>
                                <li>Venda de Equipamentos de Informática</li>
                                <li>Revenda de Máquinas de Cartão</li>
                                <li>Design Gráfico para Redes Sociais</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=      About Section Area End       =-->
<!--=====================================-->
<!--=====================================-->
<!--=      Team Section Area Start      =-->
<!--=====================================-->
<section id="team-wrap-layout1" class="section-padding-md">
    <div class="container">
        <div class="section-heading heading-dark heading-layout1">
            <div class="heading-sub-title">OS MELHORES</div>
            <h2 class="heading-main-title">Um Time Dedicado para Você</h2>
            <p class="heading-paragraph">Temos um time dedicado para melhor te atender. Cada qual com a sua função, juntos somos mais fortes.</p>
        </div>
        <div class="row has-animation rc-carousel nav-control-layout4 col-full-width"
            data-options='{"trigger_start": 991, "trigger_end": 0}' data-loop="true" data-items="30" data-margin="10"
            data-autoplay="false" data-autoplay-timeout="5000" data-smart-speed="700" data-dots="false" data-nav="true"
            data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false"
            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="2"
            data-r-small-nav="true" data-r-small-dots="false" data-r-medium="2" data-r-medium-nav="true"
            data-r-medium-dots="false" data-r-large="3" data-r-large-nav="true" data-r-large-dots="false"
            data-r-extra-large="3" data-r-extra-large-nav="true" data-r-extra-large-dots="false">
            <div class="col-md-4">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-100">
                    <div class="team-box-layout1">
                        <div class="maks-item animted-bg-wrap">
                            <span class="animted-bg"></span>
                            <div class="item-figure">
                                <img src="media/team/team.jpg" alt="Team">
                            </div>
                            <ul class="social-items">
                                <li><a href="https://www.facebook.com/patrickmguimaraes" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://twitter.com/patrickmguima" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/patrickmguimaraes/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="https://www.linkedin.com/in/patrickmguimaraes/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                        <div class="item-content">
                            <h3 class="item-title"><a href="#">Patrick Mendes Guimarães</a></h3>
                            <div class="sub-title">CEO / Analista de Sistemas / Consultor Empresarial</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-600">
                    <div class="team-box-layout1">
                        <div class="maks-item animted-bg-wrap">
                            <span class="animted-bg"></span>
                            <div class="item-figure">
                                <img src="media/team/team01.jpg" alt="Team">
                            </div>
                            <ul class="social-items">
                                <li><a href="https://www.instagram.com/f3lip3_dias/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="https://www.linkedin.com/in/felipe-dias-guimarães-2222a285/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                        <div class="item-content">
                            <h3 class="item-title"><a href="#">Felipe Dias Guimarães</a></h3>
                            <div class="sub-title">Analista de Sistemas / Técnico em Informática</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1100">
                    <div class="team-box-layout1">
                        <div class="maks-item animted-bg-wrap">
                            <span class="animted-bg"></span>
                            <div class="item-figure">
                                <img src="media/team/team02.png" alt="Team">
                            </div>
                            <ul class="social-items">
                                <li><a href="https://www.facebook.com/jackson.trindade.16" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/jacksonpdi/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="https://www.linkedin.com/in/jackson-nogueira-661a4067/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                        <div class="item-content">
                            <h3 class="item-title"><a href="#">Tamires Félix</a></h3>
                            <div class="sub-title">Vendedora / Auxiliar Administrativo</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=       Team Section Area End       =-->
<!--=====================================-->
<!--=====================================-->
<!--=     Brand Section Area Start      =-->
<!--=====================================-->
<section id="brand-wrap-layout1" class="brand-wrap-layout1 bg-color-accent2">
    <div class="container">
        <div class="rc-carousel" data-loop="true" data-items="8" data-margin="30" data-autoplay="false"
            data-autoplay-timeout="5000" data-smart-speed="700" data-dots="false" data-nav="false"
            data-nav-speed="false" data-r-x-small="2" data-r-x-small-nav="false" data-r-x-small-dots="false"
            data-r-x-medium="3" data-r-x-medium-nav="false" data-r-x-medium-dots="false" data-r-small="4"
            data-r-small-nav="false" data-r-small-dots="false" data-r-medium="4" data-r-medium-nav="false"
            data-r-medium-dots="false" data-r-large="5" data-r-large-nav="false" data-r-large-dots="false"
            data-r-extra-large="6" data-r-extra-large-nav="false" data-r-extra-large-dots="false">
            <div class="brand-box-layout1"><img src="media/brand/brand1.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand2.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand3.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand4.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand5.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand6.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand3.png" alt="Brand-thumb"></div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=      Brand Section Area End       =-->
<!--=====================================-->