import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comercializacao-sistema-erp',
  templateUrl: './comercializacao-sistema-erp.component.html',
  styleUrls: ['./comercializacao-sistema-erp.component.css']
})
export class ComercializacaoSistemaErpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
