<!--=====================================-->
<!--=         Main Banner Start         =-->
<!--=====================================-->
<section id="page-banner" class="page-banner bg-gradient-layout6 has-animation">
    <ul class="shape-holder">
        <li>
            <div class="translate-top-50 opacity-animation transition-200 transition-delay-10">
                <svg width="827px" height="827px">
                    <defs>
                        <linearGradient id="shape1" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.6" />
                            <stop offset="100%" stop-color="rgb(117,14,213)" stop-opacity="0.6" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape1)"
                        d="M413.500,0.000 C641.870,0.000 827.000,185.130 827.000,413.500 C827.000,641.870 641.870,827.000 413.500,827.000 C185.130,827.000 -0.000,641.870 -0.000,413.500 C-0.000,185.130 185.130,0.000 413.500,0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-top-50 opacity-animation transition-100 transition-delay-300">
                <svg width="615px" height="615px">
                    <defs>
                        <linearGradient id="shape2" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.6" />
                            <stop offset="100%" stop-color="rgb(109,26,223)" stop-opacity="0.6" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape2)"
                        d="M307.500,0.000 C477.327,0.000 615.000,137.673 615.000,307.500 C615.000,477.327 477.327,615.000 307.500,615.000 C137.672,615.000 -0.000,477.327 -0.000,307.500 C-0.000,137.673 137.672,0.000 307.500,0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-top-50 opacity-animation transition-50 transition-delay-600">
                <svg width="441px" height="441px">
                    <defs>
                        <linearGradient id="shape3" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="13%" stop-color="rgb(82,63,255)" stop-opacity="0.4" />
                            <stop offset="100%" stop-color="rgb(130,33,221)" stop-opacity="0.4" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape3)"
                        d="M220.500,0.000 C342.279,0.000 441.000,98.721 441.000,220.500 C441.000,342.279 342.279,441.000 220.500,441.000 C98.721,441.000 -0.000,342.279 -0.000,220.500 C-0.000,98.721 98.721,0.000 220.500,0.000 Z" />
                </svg>
            </div>
        </li>
    </ul>
    <div class="container">
        <div class="breadcrumbs-area">
            <h1>FAQ (Perguntas e Respostas)</h1>
            <ul>
                <li>
                    <a href="">Início</a>
                </li>
                <li>FAQ</li>
            </ul>
        </div>
    </div>
</section>



<!--=====================================-->
<!--=           Main Banner End         =-->
<!--=====================================-->
<!--=====================================-->
<!--=      Team Section Area Start      =-->
<!--=====================================-->
<section class="faq-wrap bg-color-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 order-lg-2">
                <h2 class="inner-section-heading">Perguntas e Respostas</h2>
                <div class="faq-box">
                    <div id="accordion">
                        <div class="card single-item">
                            <div class="card-header item-nav">
                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Existe algum desconto incluso?</a>
                            </div>
                            <div id="collapse1" class="collapse show item-content-wrap" data-parent="#accordion">
                                <div class="card-body item-content">
                                    <p>Sim. Caso o cliente queira fechar um pacote com a gente, o desconto é aplicado com base no valor final do pacote. Como vendemos vários tipos de produto e serviço, vai depender de cada venda o valor do desconto.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card single-item">
                            <div class="card-header item-nav">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                    href="#collapse2">Como funciona o pacote?</a>
                            </div>
                            <div id="collapse2" class="collapse item-content-wrap" data-parent="#accordion">
                                <div class="card-body item-content">
                                    <p>O pacote seria a comercialização de um sistema + quaisquer outro produto necessário para a implantação do mesmo, como Computador, Balança, Leitos de Código de Barras, etc. Também somos revendedores de Certificado Digital e Máquinas de Cartão. Além disso, no pacote, alguns serviços como a Consultoria Empresarial pode sair de graça.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card single-item">
                            <div class="card-header item-nav">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                    href="#collapse3">Como faço para ter a Consultoria Empresarial de graça?</a>
                            </div>
                            <div id="collapse3" class="collapse item-content-wrap" data-parent="#accordion">
                                <div class="card-body item-content">
                                    <p>Caso o cliente compre um pacote com a gente, pode ser que no pacote o valor da Consultoria de Negócio saia de graça. Para isso, precisamos fidelizar o cliente com um de nossos planos. Assim, comprando o pacote mais robusto e fidelizando por 12 meses não cobraremos o valor da Consultoria Empresarial.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card single-item">
                            <div class="card-header item-nav">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                    href="#collapse4">Afinal, o que é uma Consultoria Empresarial?</a>
                            </div>
                            <div id="collapse4" class="collapse item-content-wrap" data-parent="#accordion">
                                <div class="card-body item-content">
                                    <p>A Consultoria Empresarial é muito útil para pequenas, médias e grandes empresas. Oferecemos dois tipos de Consultoria Empresarial: Para quem está começando e para quem já possuí um negócio. Para quem tiver começando, iremos fazer uma consultoria para identificar o Plano de Negócio da empresa. Para as empresas já existentes, ofereceremos um conjunto de reuniões chamada Facilitação de Processos. Nela, o facilitador vai mostrando ao cliente os caminhos necessários para a mudança, lembrando que quem decide é sempre o cliente.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card single-item">
                            <div class="card-header item-nav">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                    href="#collapse5">O que é um Plano de Negócio?</a>
                            </div>
                            <div id="collapse5" class="collapse item-content-wrap" data-parent="#accordion">
                                <div class="card-body item-content">
                                    <p>O Plano de Negócio é um documento que contém todo o planejamento da empresa para os próximos anos. Descobriremos através dele o problema que a empresa quer solucionar. Além disso, formulamos a Missão da empresa, o Business Model Canvas, Estrutura de Custos, Posicionamento da Empresa, Análise da Concorrência, Matriz SWOT, Análise das Forças de Poter, Análise Financeira e Fluxo de Caixa Projetado. Como você viu, o Plano de Negócio é bem extenso e abrange todas as áreas da empresa. Para mais informações do que cada item que compõe o Plano de Negócio, por favor, nos procure. Com o plano de negócio em mãos, o empreendedor saberá todos os passos que irá percorrer em seu empreendimento e poderá conseguir empréstimos com melhores condições, uma vez que esse documento dará confiança ao banco que aquele negócio em questão é bem seguro.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card single-item">
                            <div class="card-header item-nav">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                    href="#collapse6">O que é um processo de Facilitação?</a>
                            </div>
                            <div id="collapse6" class="collapse item-content-wrap" data-parent="#accordion">
                                <div class="card-body item-content">
                                    <p>O processo de Facilitação é um conjunto de reuniões onde o cliente relata todo o processo da empresa para o facilitador. Após uma profunda análise, o nosso cliente chega a conclusões próprias a respeito do seu negócio. Utilizamos ferramentas como o Brainstorming para ajudar com novas ideias. Todo o processo costuma durar 3 a 4 reuniões. Na primeira é feita o Brainstorming, na segunda é feita uma reanálise, na terceira é feita o plano de ação, onde o cliente vai se comprometer a cumprir em um determinado tempo as melhoras necessárias para o seu negócio. Por fim, na quarta e última reunião é feito o Feedback no estabelecimento do cliente, onde o mesmo expõe as melhorias que ocorreram no seu estabelecimento.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 order-lg-1 template-sidebar">
                <div class="widget widget-padding bg-color-accent2">
                    <div class="widget-section-heading heading-layout1">
                        <h3 class="item-heading">Artigos em Destaque</h3>
                    </div>
                    <div class="widget-article">
                        <ul class="list-item">
                            <li><a href="#">Tudo sobre o Plano de Negócio</a></li>
                            <li><a href="#">Tudo sobre o Processo de Facilitação</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=       Team Section Area End       =-->
<!--=====================================-->
<!--=====================================-->
<!--=     Brand Section Area Start      =-->
<!--=====================================-->
<section id="brand-wrap-layout1" class="brand-wrap-layout1 bg-color-accent2">
    <div class="container">
        <div class="rc-carousel" data-loop="true" data-items="8" data-margin="30" data-autoplay="false"
            data-autoplay-timeout="5000" data-smart-speed="700" data-dots="false" data-nav="false"
            data-nav-speed="false" data-r-x-small="2" data-r-x-small-nav="false" data-r-x-small-dots="false"
            data-r-x-medium="3" data-r-x-medium-nav="false" data-r-x-medium-dots="false" data-r-small="4"
            data-r-small-nav="false" data-r-small-dots="false" data-r-medium="4" data-r-medium-nav="false"
            data-r-medium-dots="false" data-r-large="5" data-r-large-nav="false" data-r-large-dots="false"
            data-r-extra-large="6" data-r-extra-large-nav="false" data-r-extra-large-dots="false">
            <div class="brand-box-layout1"><img src="media/brand/brand1.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand2.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand3.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand4.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand5.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand6.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand3.png" alt="Brand-thumb"></div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=      Brand Section Area End       =-->
<!--=====================================-->