<!--=====================================-->
<!--=           404 Area Start          =-->
<!--=====================================-->
<section class="error-page-wrap has-animation">
    <div class="container">
        <div class="error-page">
            <div class="item-figure">
                <div class="translate-zoomout-50 opacity-animation transition-200 transition-delay-100">
                    <img src="media/illustration/404.png" alt="404">
                </div>
            </div>
            <div class="item-content">
                <div class="translate-bottom-75 opacity-animation transition-200 transition-delay-500">
                    <h2 class="item-title">Página não encontrada</h2>
                </div>
                <div class="translate-bottom-75 opacity-animation transition-200 transition-delay-1000">
                    <p>Desculpe, a página que você procurou não existe em nosso banco de dados! Talvez vá para nossa página inicial ou tente usar uma pesquisa?</p>
                </div>
                <div class="translate-bottom-75 opacity-animation transition-200 transition-delay-1500">
                    <a href="" class="btn-fill btn-gradient">Voltar para Página Inicial</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=            404 Area End           =-->
<!--=====================================-->