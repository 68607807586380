export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDcsMb2xV05XRbEHm-I7TNQ5NDhAoWIFFQ",
    authDomain: "pmg-systems.firebaseapp.com",
    projectId: "pmg-systems",
    storageBucket: "pmg-systems.appspot.com",
    messagingSenderId: "695505709576",
    appId: "1:695505709576:web:bae71bee2c883044313fbd",
    measurementId: "G-CZMT86F9VE"
  }
};
