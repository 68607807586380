import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emissao-certificado-digital',
  templateUrl: './emissao-certificado-digital.component.html',
  styleUrls: ['./emissao-certificado-digital.component.css']
})
export class EmissaoCertificadoDigitalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
