<!--=====================================-->
<!--=         Main Banner Start         =-->
<!--=====================================-->
<section id="page-banner" class="page-banner bg-gradient-layout6 has-animation">
    <ul class="shape-holder">
        <li>
            <div class="translate-top-50 opacity-animation transition-200 transition-delay-10">
                <svg width="827px" height="827px">
                    <defs>
                        <linearGradient id="shape1" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.6" />
                            <stop offset="100%" stop-color="rgb(117,14,213)" stop-opacity="0.6" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape1)"
                        d="M413.500,0.000 C641.870,0.000 827.000,185.130 827.000,413.500 C827.000,641.870 641.870,827.000 413.500,827.000 C185.130,827.000 -0.000,641.870 -0.000,413.500 C-0.000,185.130 185.130,0.000 413.500,0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-top-50 opacity-animation transition-100 transition-delay-300">
                <svg width="615px" height="615px">
                    <defs>
                        <linearGradient id="shape2" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.6" />
                            <stop offset="100%" stop-color="rgb(109,26,223)" stop-opacity="0.6" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape2)"
                        d="M307.500,0.000 C477.327,0.000 615.000,137.673 615.000,307.500 C615.000,477.327 477.327,615.000 307.500,615.000 C137.672,615.000 -0.000,477.327 -0.000,307.500 C-0.000,137.673 137.672,0.000 307.500,0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-top-50 opacity-animation transition-50 transition-delay-600">
                <svg width="441px" height="441px">
                    <defs>
                        <linearGradient id="shape3" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="13%" stop-color="rgb(82,63,255)" stop-opacity="0.4" />
                            <stop offset="100%" stop-color="rgb(130,33,221)" stop-opacity="0.4" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape3)"
                        d="M220.500,0.000 C342.279,0.000 441.000,98.721 441.000,220.500 C441.000,342.279 342.279,441.000 220.500,441.000 C98.721,441.000 -0.000,342.279 -0.000,220.500 C-0.000,98.721 98.721,0.000 220.500,0.000 Z" />
                </svg>
            </div>
        </li>
    </ul>
    <div class="container">
        <div class="breadcrumbs-area">
            <h1>Portfolio Grid 2 Column</h1>
            <ul>
                <li>
                    <a href="index.html">Home</a>
                </li>
                <li>Portfolio</li>
            </ul>
        </div>
    </div>
</section>



<!--=====================================-->
<!--=           Main Banner End         =-->
<!--=====================================-->
<!--=====================================-->
<!--=    Gallery Section Area Start     =-->
<!--=====================================-->
<section class="gallery-wrap-layout2 section-padding-md-equal bg-color-light">
    <div class="container">
        <div class="isotope-wrap">
            <div class="isotope-classes-tab isotop-btn-layout1">
                <a href="#" class="current nav-item" data-filter=".all">See All</a>
                <a href="#" class="nav-item" data-filter=".branding">Branding</a>
                <a href="#" class="nav-item" data-filter=".design">Web Design </a>
                <a href="#" class="nav-item" data-filter=".ui">UI/UX</a>
                <a href="#" class="nav-item" data-filter=".product">Product</a>
            </div>
            <div class="row featuredContainer">
                <div class="col-md-6 col-12 all branding illustrations">
                    <div class="gallery-box-layout1">
                        <div class="item-figure">
                            <img src="media/gallery/gallery5.jpg" alt="gallery">
                        </div>
                        <div class="item-content">
                            <div class="item-icon">
                                <a href="media/gallery/gallery5.jpg" class="popup-zoom" data-fancybox-group="gallery"
                                    title=""><i class="fas fa-plus"></i></a>
                            </div>
                            <h3 class="item-title"><a href="gallery-details.html">From Print to Platform</a></h3>
                            <p>Branding Items</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 all ui product">
                    <div class="gallery-box-layout1">
                        <div class="item-figure">
                            <img src="media/gallery/gallery6.jpg" alt="gallery">
                        </div>
                        <div class="item-content">
                            <div class="item-icon">
                                <a href="media/gallery/gallery6.jpg" class="popup-zoom" data-fancybox-group="gallery"
                                    title=""><i class="fas fa-plus"></i></a>
                            </div>
                            <h3 class="item-title"><a href="gallery-details.html">From Print to Platform</a></h3>
                            <p>Branding Items</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 all branding design">
                    <div class="gallery-box-layout1">
                        <div class="item-figure">
                            <img src="media/gallery/gallery7.jpg" alt="gallery">
                        </div>
                        <div class="item-content">
                            <div class="item-icon">
                                <a href="media/gallery/gallery7.jpg" class="popup-zoom" data-fancybox-group="gallery"
                                    title=""><i class="fas fa-plus"></i></a>
                            </div>
                            <h3 class="item-title"><a href="gallery-details.html">From Print to Platform</a></h3>
                            <p>Branding Items</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 all ui">
                    <div class="gallery-box-layout1">
                        <div class="item-figure">
                            <img src="media/gallery/gallery8.jpg" alt="gallery">
                        </div>
                        <div class="item-content">
                            <div class="item-icon">
                                <a href="media/gallery/gallery8.jpg" class="popup-zoom" data-fancybox-group="gallery"
                                    title=""><i class="fas fa-plus"></i></a>
                            </div>
                            <h3 class="item-title"><a href="gallery-details.html">From Print to Platform</a></h3>
                            <p>Branding Items</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 all branding ui">
                    <div class="gallery-box-layout1">
                        <div class="item-figure">
                            <img src="media/gallery/gallery9.jpg" alt="gallery">
                        </div>
                        <div class="item-content">
                            <div class="item-icon">
                                <a href="media/gallery/gallery9.jpg" class="popup-zoom" data-fancybox-group="gallery"
                                    title=""><i class="fas fa-plus"></i></a>
                            </div>
                            <h3 class="item-title"><a href="gallery-details.html">From Print to Platform</a></h3>
                            <p>Branding Items</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 all design ui">
                    <div class="gallery-box-layout1">
                        <div class="item-figure">
                            <img src="media/gallery/gallery10.jpg" alt="gallery">
                        </div>
                        <div class="item-content">
                            <div class="item-icon">
                                <a href="media/gallery/gallery10.jpg" class="popup-zoom" data-fancybox-group="gallery"
                                    title=""><i class="fas fa-plus"></i></a>
                            </div>
                            <h3 class="item-title"><a href="gallery-details.html">From Print to Platform</a></h3>
                            <p>Branding Items</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 all branding ui">
                    <div class="gallery-box-layout1">
                        <div class="item-figure">
                            <img src="media/gallery/gallery11.jpg" alt="gallery">
                        </div>
                        <div class="item-content">
                            <div class="item-icon">
                                <a href="media/gallery/gallery11.jpg" class="popup-zoom" data-fancybox-group="gallery"
                                    title=""><i class="fas fa-plus"></i></a>
                            </div>
                            <h3 class="item-title"><a href="gallery-details.html">From Print to Platform</a></h3>
                            <p>Branding Items</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 all product">
                    <div class="gallery-box-layout1">
                        <div class="item-figure">
                            <img src="media/gallery/gallery12.jpg" alt="gallery">
                        </div>
                        <div class="item-content">
                            <div class="item-icon">
                                <a href="media/gallery/gallery12.jpg" class="popup-zoom" data-fancybox-group="gallery"
                                    title=""><i class="fas fa-plus"></i></a>
                            </div>
                            <h3 class="item-title"><a href="gallery-details.html">From Print to Platform</a></h3>
                            <p>Branding Items</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 all design">
                    <div class="gallery-box-layout1">
                        <div class="item-figure">
                            <img src="media/gallery/gallery13.jpg" alt="gallery">
                        </div>
                        <div class="item-content">
                            <div class="item-icon">
                                <a href="media/gallery/gallery13.jpg" class="popup-zoom" data-fancybox-group="gallery"
                                    title=""><i class="fas fa-plus"></i></a>
                            </div>
                            <h3 class="item-title"><a href="gallery-details.html">From Print to Platform</a></h3>
                            <p>Branding Items</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 all branding ul">
                    <div class="gallery-box-layout1">
                        <div class="item-figure">
                            <img src="media/gallery/gallery14.jpg" alt="gallery">
                        </div>
                        <div class="item-content">
                            <div class="item-icon">
                                <a href="media/gallery/gallery14.jpg" class="popup-zoom" data-fancybox-group="gallery"
                                    title=""><i class="fas fa-plus"></i></a>
                            </div>
                            <h3 class="item-title"><a href="gallery-details.html">From Print to Platform</a></h3>
                            <p>Branding Items</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=     Gallery Section Area End      =-->
<!--=====================================-->
<!--=====================================-->
<!--=     Brand Section Area Start      =-->
<!--=====================================-->
<section id="brand-wrap-layout1" class="brand-wrap-layout1 bg-color-accent2">
    <div class="container">
        <div class="rc-carousel" data-loop="true" data-items="8" data-margin="30" data-autoplay="false"
            data-autoplay-timeout="5000" data-smart-speed="700" data-dots="false" data-nav="false"
            data-nav-speed="false" data-r-x-small="2" data-r-x-small-nav="false" data-r-x-small-dots="false"
            data-r-x-medium="3" data-r-x-medium-nav="false" data-r-x-medium-dots="false" data-r-small="4"
            data-r-small-nav="false" data-r-small-dots="false" data-r-medium="4" data-r-medium-nav="false"
            data-r-medium-dots="false" data-r-large="5" data-r-large-nav="false" data-r-large-dots="false"
            data-r-extra-large="6" data-r-extra-large-nav="false" data-r-extra-large-dots="false">
            <div class="brand-box-layout1"><img src="media/brand/brand1.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand2.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand3.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand4.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand5.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand6.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand3.png" alt="Brand-thumb"></div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=      Brand Section Area End       =-->
<!--=====================================-->