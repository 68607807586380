<!--=====================================-->
<!--=         Main Banner Start         =-->
<!--=====================================-->
<section id="page-banner" class="page-banner bg-gradient-layout6 has-animation">
    <ul class="shape-holder">
        <li>
            <div class="translate-top-50 opacity-animation transition-200 transition-delay-10">
                <svg width="827px" height="827px">
                    <defs>
                        <linearGradient id="shape1" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.6" />
                            <stop offset="100%" stop-color="rgb(117,14,213)" stop-opacity="0.6" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape1)"
                        d="M413.500,0.000 C641.870,0.000 827.000,185.130 827.000,413.500 C827.000,641.870 641.870,827.000 413.500,827.000 C185.130,827.000 -0.000,641.870 -0.000,413.500 C-0.000,185.130 185.130,0.000 413.500,0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-top-50 opacity-animation transition-100 transition-delay-300">
                <svg width="615px" height="615px">
                    <defs>
                        <linearGradient id="shape2" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.6" />
                            <stop offset="100%" stop-color="rgb(109,26,223)" stop-opacity="0.6" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape2)"
                        d="M307.500,0.000 C477.327,0.000 615.000,137.673 615.000,307.500 C615.000,477.327 477.327,615.000 307.500,615.000 C137.672,615.000 -0.000,477.327 -0.000,307.500 C-0.000,137.673 137.672,0.000 307.500,0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-top-50 opacity-animation transition-50 transition-delay-600">
                <svg width="441px" height="441px">
                    <defs>
                        <linearGradient id="shape3" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="13%" stop-color="rgb(82,63,255)" stop-opacity="0.4" />
                            <stop offset="100%" stop-color="rgb(130,33,221)" stop-opacity="0.4" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape3)"
                        d="M220.500,0.000 C342.279,0.000 441.000,98.721 441.000,220.500 C441.000,342.279 342.279,441.000 220.500,441.000 C98.721,441.000 -0.000,342.279 -0.000,220.500 C-0.000,98.721 98.721,0.000 220.500,0.000 Z" />
                </svg>
            </div>
        </li>
    </ul>
    <div class="container">
        <div class="breadcrumbs-area">
            <h1>Orçamento</h1>
            <ul>
                <li>
                    <a href="">Início</a>
                </li>
                <li>Orçamento</li>
            </ul>
        </div>
    </div>
</section>



<!--=====================================-->
<!--=           Main Banner End         =-->
<!--=====================================-->
<!--=====================================-->
<!--=      Team Section Area Start      =-->
<!--=====================================-->
<section class="contact-wrap-layout3 section-padding-md-equal">
    <div class="container">
        <div class="row gutters-50">
            <div class="col-lg-4 mb-5 has-animation">
                <div class="single-item translate-left-75 opacity-animation transition-150 transition-delay-100">
                    <div class="address-box-layout2">
                        <div class="item-icon">
                            <i class="flaticon-mail"></i>
                        </div>
                        <div class="item-content">
                            <h3 class="item-title">E-mail &amp; Telefone</h3>
                            <ul class="list-item">
                                <li>pmg.systems@outlook.com</li>
                                <li>+55 (77) 9 8146-6811</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="single-item translate-left-75 opacity-animation transition-150 transition-delay-600">
                    <div class="address-box-layout2">
                        <div class="item-icon">
                            <i class="flaticon-placeholder"></i>
                        </div>
                        <div class="item-content">
                            <h3 class="item-title">Nossa localização</h3>
                            <ul class="list-item">
                                <li>Rua Ayrton Senna, 18</li>
                                <li>Vila Nova</li>
                                <li>Pindaí-BA</li>
                                <li>46360-000</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="single-item translate-left-75 opacity-animation transition-150 transition-delay-1100">
                    <div class="address-box-layout2">
                        <div class="item-icon">
                            <i class="flaticon-share"></i>
                        </div>
                        <div class="item-content">
                            <h3 class="item-title">Nossa rede</h3>
                            <ul class="list-item">
                                <li><a href="http://instagram.com/_u/pmgsystems" target="_blanck">Instagram: @pmgsystems</a></li>
                                <li><a href="https://www.facebook.com/pmgsystems" target="_blanck">Facebook: @pmgsystems</a></li>
                                <li><a href="https://www.linkedin.com/company/pmg-systems" target="_blanck">LinkedIn: pmg-systems</a></li>
                                <li><a href="https://twitter.com/pmgsystems" target="_blanck">Twitter: @pmgsystems</a></li>
                                <li><a href="https://www.youtube.com/channel/UCFAKyEJoWoUYT0BvfqX3sww" target="_blanck">YourTube: PMG Systems</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 has-animation">
                <div class="contact-box-layout3">
                    <form class="contact-form-box" id="contact-form-removed"  [formGroup]="formOrcamento" (ngSubmit)="sendOrcamento()">
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <div class="translate-bottom-50 opacity-animation transition-100 transition-delay-1200">
                                    <input type="text" formControlName="nome" placeholder="Nome *" class="form-control" name="name" autocomplete="name"
                                        data-error="Name field is required" required>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                                <div class="translate-bottom-50 opacity-animation transition-100 transition-delay-1400">
                                    <input type="email" formControlName="email" placeholder="E-mail *" class="form-control" name="email"
                                        data-error="Email field is required" required>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                                <div class="translate-bottom-50 opacity-animation transition-100 transition-delay-1600">
                                    <input type="text" formControlName="telefone" placeholder="Telefone *" class="form-control" name="phone"
                                        data-error="Telefone field is required" required>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-12 form-group">
                                <div class="translate-bottom-50 opacity-animation transition-100 transition-delay-1800">
                                    <select class="form-control" name="subject" formControlName="tipo" required>
                                        <option value="Desenvolvimento de Sistemas Customizados">Desenvolvimento de Sistemas/Sites Customizados</option>
                                        <option value="Consultoria Empresarial">Consultoria Empresarial</option>
                                        <option value="Consultoria Estratégica">Consultoria Estratégica</option>
                                        <option value="Comercialização de Sistemas PDV">Comercialização de Sistemas PDV</option>
                                        <option value="Comercialização de Sistemas de Gestão (ERP)">Comercialização de Sistemas de Gestão (ERP)</option>
                                        <option value="Emissão de Certificado Digital">Emissão de Certificado Digital</option>
                                        <option value="Venda de Equipamentos de Informática">Venda de Equipamentos de Informática</option>
                                        <option value="Revenda de Máquinas de Cartão">Revenda de Máquinas de Cartão</option>
                                        <option value="Design Gráfico para Redes Sociais">Design Gráfico para Redes Sociais</option>
                                    </select>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-12 form-group">
                                <div class="translate-bottom-50 opacity-animation transition-100 transition-delay-2000">
                                    <textarea formControlName="mensagem" placeholder="Mensagem" class="textarea form-control" name="message"
                                        id="form-message" rows="5" cols="20" data-error="Message field is required"></textarea>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-12 form-group mb-0">
                                <div class="translate-bottom-50 opacity-animation transition-100 transition-delay-2200">
                                    <button type="submit" class="btn-fill btn-gradient">Enviar Mensagem</button>
                                    <div *ngIf="mensagem">
                                        <br/>
                                        <label style="color: blue;">{{mensagem}}</label>
                                        <br/>
                                    </div>
                                    <div *ngIf="mensagemErro">
                                        <br/>
                                        <label style="color: red;">{{mensagemErro}}</label>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-response"></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=       Team Section Area End       =-->
<!--=====================================-->
<!--=====================================-->
<!--=     Brand Section Area Start      =-->
<!--=====================================-->
<section id="brand-wrap-layout1" class="brand-wrap-layout1 bg-color-accent2">
    <div class="container">
        <div class="rc-carousel" data-loop="true" data-items="8" data-margin="30" data-autoplay="false"
            data-autoplay-timeout="5000" data-smart-speed="700" data-dots="false" data-nav="false"
            data-nav-speed="false" data-r-x-small="2" data-r-x-small-nav="false" data-r-x-small-dots="false"
            data-r-x-medium="3" data-r-x-medium-nav="false" data-r-x-medium-dots="false" data-r-small="4"
            data-r-small-nav="false" data-r-small-dots="false" data-r-medium="4" data-r-medium-nav="false"
            data-r-medium-dots="false" data-r-large="5" data-r-large-nav="false" data-r-large-dots="false"
            data-r-extra-large="6" data-r-extra-large-nav="false" data-r-extra-large-dots="false">
            <div class="brand-box-layout1"><img src="media/brand/brand1.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand2.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand3.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand4.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand5.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand6.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand3.png" alt="Brand-thumb"></div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=      Brand Section Area End       =-->
<!--=====================================-->