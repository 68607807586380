<!--=====================================-->
<!--=         Main Banner Start         =-->
<!--=====================================-->
<section id="page-banner" class="page-banner bg-gradient-layout6 has-animation">
    <ul class="shape-holder">
        <li>
            <div class="translate-top-50 opacity-animation transition-200 transition-delay-10">
                <svg width="827px" height="827px">
                    <defs>
                        <linearGradient id="shape1" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.6" />
                            <stop offset="100%" stop-color="rgb(117,14,213)" stop-opacity="0.6" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape1)"
                        d="M413.500,0.000 C641.870,0.000 827.000,185.130 827.000,413.500 C827.000,641.870 641.870,827.000 413.500,827.000 C185.130,827.000 -0.000,641.870 -0.000,413.500 C-0.000,185.130 185.130,0.000 413.500,0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-top-50 opacity-animation transition-100 transition-delay-300">
                <svg width="615px" height="615px">
                    <defs>
                        <linearGradient id="shape2" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.6" />
                            <stop offset="100%" stop-color="rgb(109,26,223)" stop-opacity="0.6" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape2)"
                        d="M307.500,0.000 C477.327,0.000 615.000,137.673 615.000,307.500 C615.000,477.327 477.327,615.000 307.500,615.000 C137.672,615.000 -0.000,477.327 -0.000,307.500 C-0.000,137.673 137.672,0.000 307.500,0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-top-50 opacity-animation transition-50 transition-delay-600">
                <svg width="441px" height="441px">
                    <defs>
                        <linearGradient id="shape3" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="13%" stop-color="rgb(82,63,255)" stop-opacity="0.4" />
                            <stop offset="100%" stop-color="rgb(130,33,221)" stop-opacity="0.4" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape3)"
                        d="M220.500,0.000 C342.279,0.000 441.000,98.721 441.000,220.500 C441.000,342.279 342.279,441.000 220.500,441.000 C98.721,441.000 -0.000,342.279 -0.000,220.500 C-0.000,98.721 98.721,0.000 220.500,0.000 Z" />
                </svg>
            </div>
        </li>
    </ul>
    <div class="container">
        <div class="breadcrumbs-area">
            <h1>Construção de Sites/Sistemas Customizados</h1>
            <ul>
                <li>
                    <a href="">Início</a>
                </li>
                <li>
                    <a href="servicos">Serviços</a>
                </li>
                <li>Construção de Sites/Sistemas Customizados</li>
            </ul>
        </div>
    </div>
</section>

<!--=====================================-->
<!--=           Main Banner End         =-->
<!--=====================================-->
<!--=====================================-->
<!--=      Team Section Area Start      =-->
<!--=====================================-->
<section class="service-details-wrap section-padding-md-equal bg-color-accent2">
    <div class="container">
        <div class="row mb--60 d-flex align-items-center">
            <div class="col-lg-6 has-animation pr-5">
                <div class="service-details-box">
                    <div class="content-holder">
                        <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-600">
                            <h2 class="item-title">Nós estamos aqui para desenvolver o seu Website</h2>
                        </div>
                        <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1100">
                            <div class="sub-paragraph">Trabalhamos com as melhores linguagens de programação do mercado, como HTML5, JavaScript, Angular, React e Angular.</div>
                        </div>
                        <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1600">
                            <p>Um site serve para muitas coisas: divulgar a sua empresa, ser seu cartão postal 24h por dia, fazer camapanhas publicitárias pela internet, comprar, vender, utilizar como rede social, etc. Ter um site hoje em dia não é tão custoso como antigamente. As pessoas estão valorizando cada vez mais quem tem um site. É por isso que você não pode ficar fora dessa, aproveite os nossos preços promocionais e venha fazer um com a gente.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 has-animation">
                <div class="service-details-box">
                    <div class="item-figure">
                        <div class="translate-right-75 opacity-animation transition-150 transition-delay-500">
                            <img src="media/illustration/illustration28.png" alt="Service">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row box-bottom-margin has-animation">
            <div class="col-xl-3 col-sm-6 col-12">
                <div class="service-details-box">
                    <div class="translate-zoomout-50 opacity-animation transition-150 transition-delay-100">
                        <div class="feature-item dodger-blue">
                            <div class="feature-icon"><i class="flaticon-responsive"></i></div>
                            <div class="feature-number">1</div>
                            <h3 class="feature-title">Design Responsivo</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
                <div class="service-details-box">
                    <div class="translate-zoomout-50 opacity-animation transition-150 transition-delay-600">
                        <div class="feature-item sunset-orange">
                            <div class="feature-icon"><i class="flaticon-hand"></i></div>
                            <div class="feature-number">2</div>
                            <h3 class="feature-title">Teste de Funcionalidades</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
                <div class="service-details-box">
                    <div class="translate-zoomout-50 opacity-animation transition-150 transition-delay-1100">
                        <div class="feature-item royal-blue">
                            <div class="feature-icon"><i class="flaticon-canvas"></i></div>
                            <div class="feature-number">3</div>
                            <h3 class="feature-title">Limpo &amp; Moderno</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
                <div class="service-details-box">
                    <div class="translate-zoomout-50 opacity-animation transition-150 transition-delay-1600">
                        <div class="feature-item california">
                            <div class="feature-icon"><i class="flaticon-goal"></i></div>
                            <div class="feature-number">4</div>
                            <h3 class="feature-title">Grande Experiência</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex align-items-center has-animation">
            <div class="col-lg-6 has-animation order-lg-2">
                <div class="service-details-box">
                    <div class="content-holder">
                        <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-100">
                            <h2 class="item-title">Precisa de um Sistema para solucionar um problema? Conte com a gente.
                            </h2>
                        </div>
                        <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-600">
                            <div class="sub-paragraph">Trabalhamos com as melhores linguagens de programação do mercado, como Java, Oracle e PostgreSQL.</div>
                        </div>
                        <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1100">
                            <p>Um sistema pode servir para muitas coisas. Já imaginou o seu negócio informatizado e utilizando um sistema? Customizar sistemas é primordial para atendede toda a demanda da sua empresa. Você também pode transformar uma ideia em um aplicativo de celular, por exemplo. Enfim, o sistema serve para muitas coisas e nós estamos aqui para te ajudar.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 has-animation order-lg-1">
                <div class="service-details-box">
                    <div class="item-figure">
                        <div class="translate-left-75 opacity-animation transition-150 transition-delay-100">
                            <img src="media/illustration/illustration20.png" alt="Service">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=       Team Section Area End       =-->
<!--=====================================-->
<!--=====================================-->
<!--=     Brand Section Area Start      =-->
<!--=====================================-->
<section id="brand-wrap-layout1" class="brand-wrap-layout1 bg-color-accent5">
    <div class="container">
        <div class="rc-carousel" data-loop="true" data-items="8" data-margin="30" data-autoplay="true"
            data-autoplay-timeout="5000" data-smart-speed="700" data-dots="false" data-nav="false"
            data-nav-speed="false" data-r-x-small="2" data-r-x-small-nav="false" data-r-x-small-dots="false"
            data-r-x-medium="2" data-r-x-medium-nav="false" data-r-x-medium-dots="false" data-r-small="3"
            data-r-small-nav="false" data-r-small-dots="false" data-r-medium="4" data-r-medium-nav="false"
            data-r-medium-dots="false" data-r-large="5" data-r-large-nav="false" data-r-large-dots="false"
            data-r-extra-large="6" data-r-extra-large-nav="false" data-r-extra-large-dots="false">
            <div class="brand-box-layout1"><img src="media/brand/brand1.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand2.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand3.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand4.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand5.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand6.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand3.png" alt="Brand-thumb"></div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=      Brand Section Area End       =-->
<!--=====================================-->