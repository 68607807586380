<!--=====================================-->
<!--=         Main Banner Start         =-->
<!--=====================================-->
<section id="page-banner" class="page-banner bg-gradient-layout6 has-animation">
    <ul class="shape-holder">
        <li>
            <div class="translate-top-50 opacity-animation transition-200 transition-delay-10">
                <svg width="827px" height="827px">
                    <defs>
                        <linearGradient id="shape1" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.6" />
                            <stop offset="100%" stop-color="rgb(117,14,213)" stop-opacity="0.6" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape1)"
                        d="M413.500,0.000 C641.870,0.000 827.000,185.130 827.000,413.500 C827.000,641.870 641.870,827.000 413.500,827.000 C185.130,827.000 -0.000,641.870 -0.000,413.500 C-0.000,185.130 185.130,0.000 413.500,0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-top-50 opacity-animation transition-100 transition-delay-300">
                <svg width="615px" height="615px">
                    <defs>
                        <linearGradient id="shape2" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.6" />
                            <stop offset="100%" stop-color="rgb(109,26,223)" stop-opacity="0.6" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape2)"
                        d="M307.500,0.000 C477.327,0.000 615.000,137.673 615.000,307.500 C615.000,477.327 477.327,615.000 307.500,615.000 C137.672,615.000 -0.000,477.327 -0.000,307.500 C-0.000,137.673 137.672,0.000 307.500,0.000 Z" />
                </svg>
            </div>
        </li>
        <li>
            <div class="translate-top-50 opacity-animation transition-50 transition-delay-600">
                <svg width="441px" height="441px">
                    <defs>
                        <linearGradient id="shape3" x1="0%" x2="50%" y1="86.603%" y2="0%">
                            <stop offset="13%" stop-color="rgb(82,63,255)" stop-opacity="0.4" />
                            <stop offset="100%" stop-color="rgb(130,33,221)" stop-opacity="0.4" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#shape3)"
                        d="M220.500,0.000 C342.279,0.000 441.000,98.721 441.000,220.500 C441.000,342.279 342.279,441.000 220.500,441.000 C98.721,441.000 -0.000,342.279 -0.000,220.500 C-0.000,98.721 98.721,0.000 220.500,0.000 Z" />
                </svg>
            </div>
        </li>
    </ul>
    <div class="container">
        <div class="breadcrumbs-area">
            <h1>Nossos preços</h1>
            <ul>
                <li>
                    <a href="">Início</a>
                </li>
                <li>Preços</li>
            </ul>
        </div>
    </div>
</section>

<!--=====================================-->
<!--=           Main Banner End         =-->
<!--=====================================-->
<!--=====================================-->
<!--=    Pricing Section Area Start     =-->
<!--=====================================-->
<section class="pricing-wrap-layout1 section-padding-md bg-gradient-layout3 overflow-hidden">
    <div class="container">
        <div class="section-heading heading-dark heading-layout1">
            <div class="heading-sub-title">PLANOS DE SISTEMAS PDV/ERP</div>
            <h2 class="heading-main-title">Planos de Sistemas PDV/ERP Imperdivéis</h2>
            <p class="heading-paragraph">Trabalhamos com vários parceiros para melhor te atender. Temos um plano ideal para o seu negócio.</p>
        </div>
        <div class="row no-gutters pricing-box-devider pricing-shadow box-bottom-margin has-animation">
            <div class="col-md-4 col-12">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-100">
                    <div class="pricing-box-layout1">
                        <div class="item-icon color-california"><i class="flaticon-origami"></i></div>
                        <h3 class="item-title">Sistema ARPA Control Básico</h3>
                        <ul class="block-list">
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>1 Licença Sistema Retarguarda</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>1 Licença Sistema PDV Fiscal</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Licença Adicional Retarguarda (Ilimitado)</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Licença Adicional PDV Fiscal Online (Ilimitado)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Orçamento</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle de Estoque</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Adicional Check Stock (R$20,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle Financeiro</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Emissão de SAT, NF-e, NFS-e, NFC-e</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Emissão de NFS-e</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Ordem de Produção</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Controle de Matriz e Filial</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>SPED Fiscal</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Relatórios</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Etiquetas</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Módulo Pratto Adicional (R$50,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Suporte Técnica ao cliente</li>
                        </ul>
                        <div class="item-price"><span class="currency">R$</span>100,00<span class="item-duration">Por Mês</span> </div>
                        <!--<a href="#" class="item-btn btn-fill">Order Now</a>-->
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-600">
                    <div class="pricing-box-layout1">
                        <div class="item-icon color-emerald"><i class="flaticon-aircraft"></i></div>
                        <h3 class="item-title">Sistema ARPA Control Intermediário</h3>
                        <ul class="block-list">
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>3 Licenças Sistema Retarguarda</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>1 Licença Sistema PDV Fiscal</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Licença Adicional Retarguarda (Ilimitado)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Licença Adicional PDV Fiscal Online (Ilimitado)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Orçamento</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle de Estoque</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Adicional Check Stock (R$20,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle Financeiro</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Emissão de SAT, NF-e, NFS-e, NFC-e</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Emissão de NFS-e</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Ordem de Produção</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Controle de Matriz e Filial</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>SPED Fiscal</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Relatórios</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Etiquetas</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Módulo Pratto Adicional (R$50,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Suporte Técnica ao cliente</li>
                        </ul>
                        <div class="item-price"><span class="currency">R$</span>150,00<span class="item-duration">Por Mês</span> </div>
                        <!--<a href="#" class="item-btn btn-fill">Order Now</a>-->
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1100">
                    <div class="pricing-box-layout1">
                        <div class="item-icon color-sunset-orange"><i class="flaticon-plane-1"></i></div>
                        <h3 class="item-title">Sistema ARPA Control Completo</h3>
                        <ul class="block-list">
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>2 Licenças Sistema Retarguarda</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>1 Licença Sistema PDV Fiscal</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Licença Adicional Retarguarda (Ilimitado)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Licença Adicional PDV Fiscal Online (Ilimitado)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Orçamento</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle de Estoque</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Adicional Check Stock (R$20,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle Financeiro</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Emissão de SAT, NF-e, NFS-e, NFC-e</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Emissão de NFS-e</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Ordem de Produção</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle de Matriz e Filial</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>SPED Fiscal</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Relatórios</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Etiquetas</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Módulo Pratto Adicional (R$50,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Suporte Técnica ao cliente</li>
                        </ul>
                        <div class="item-price"><span class="currency">R$</span>200,00<span class="item-duration">Por Mês</span> </div>
                        <!--<a href="#" class="item-btn btn-fill">Order Now</a>-->
                    </div>
                </div>
            </div>
        </div>

        <div class="row no-gutters pricing-box-devider pricing-shadow box-bottom-margin has-animation">
            <div class="col-md-4 col-12">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-100">
                    <div class="pricing-box-layout1">
                        <div class="item-icon color-california"><i class="flaticon-origami"></i></div>
                        <h3 class="item-title">Sistema Siggma Comercial</h3>
                        <ul class="block-list">
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>1 Licença Sistema Siggma Comercial</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Licença Adicional Siggma Comercial (R$30,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Sistema Siggma / SPED Adicional (R$150,00)</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Sistema Siggma Industrial</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Sistema Siggma Transporte</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Orçamento</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle de Estoque</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle Financeiro</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Emissão de SAT, NFS-e, NF-e, NFS-e, NFC-e</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Ordem de Produção</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Controle de Matriz e Filial</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Etiquetas</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Relatórios</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Suporte Técnica ao cliente</li>
                        </ul>
                        <div class="item-price"><span class="currency">R$</span>250,00<span class="item-duration">Por Mês</span> </div>
                        <!--<a href="#" class="item-btn btn-fill">Order Now</a>-->
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-600">
                    <div class="pricing-box-layout1">
                        <div class="item-icon color-emerald"><i class="flaticon-aircraft"></i></div>
                        <h3 class="item-title">Sistema Siggma Industrial</h3>
                        <ul class="block-list">
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>1 Licença Sistema Siggma Comercial</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Licença Adicional Siggma Comercial (R$30,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Sistema Siggma / SPED Adicional (R$150,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Sistema Siggma Industrial</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Sistema Siggma Transporte</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Orçamento</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle de Estoque</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle Financeiro</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Emissão de SAT, NFS-e, NF-e, NFS-e, NFC-e</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Ordem de Produção</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle de Matriz e Filial</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Etiquetas</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Relatórios</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Suporte Técnica ao cliente</li>
                        </ul>
                        <div class="item-price"><span class="currency">R$</span>450,00<span class="item-duration">Por Mês</span> </div>
                        <!--<a href="#" class="item-btn btn-fill">Order Now</a>-->
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1600">
                    <div class="pricing-box-layout1">
                        <div class="item-icon color-sunset-orange"><i class="flaticon-plane-1"></i></div>
                        <h3 class="item-title">Sistema Siggma Transporte</h3>
                        <ul class="block-list">
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>1 Licença Sistema Siggma Comercial</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Licença Adicional Siggma Comercial (R$30,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Sistema Siggma / SPED Adicional (R$150,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Sistema Siggma Industrial</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Sistema Siggma Transporte</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Orçamento</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle de Estoque</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle Financeiro</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Emissão de SAT, NFS-e, NF-e, NFS-e, NFC-e</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Ordem de Produção</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Controle de Matriz e Filial</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Etiquetas</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Relatórios</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Suporte Técnica ao cliente</li>
                        </ul>
                        <div class="item-price"><span class="currency">R$</span>600,00<span class="item-duration">Por Mês</span> </div>
                        <!--<a href="#" class="item-btn btn-fill">Order Now</a>-->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="section-heading heading-dark heading-layout1">
            <div class="heading-sub-title">PREÇOS DE SITES/SISTEMAS</div>
            <h2 class="heading-main-title">Preços de Sites/Sistemas Imperdivéis</h2>
            <p class="heading-paragraph">Precisa de um Site/Sistema para o seu negócio? Conte com a gente. Temos o melhor preço e atendemos com a melhor qualidade.</p>
        </div>
        <div class="row no-gutters pricing-box-devider pricing-shadow box-bottom-margin has-animation">
            <div class="col-md-4 col-12">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-100">
                    <div class="pricing-box-layout1">
                        <div class="item-icon color-california"><i class="flaticon-origami"></i></div>
                        <h3 class="item-title">Construção de Site/Sistema Simples</h3>
                        <ul class="block-list">
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Site feito em HTML5, JavaScript e Angular</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Hospedagem Firebase</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Hospedagem Heroku</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Sistema feito em Java</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Banco de Dados relacional (MySQL, PostgreSQL, Oracle)</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Site feito em React</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Criação de Aplicativos iOS e Android</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Funcionalidade de Blog</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Funcionalidade de Orçamento</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Cadastro de usuário</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Realizar pagamento online</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Integração com sistema ERP/PDV</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Manutenção do sistema quando houver necessidade (R$200,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Instalação do Domínio e SSL (R$100,00 anuais)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Criação do Google Meu Negócio e Apple Business (R$100,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Gerenciar perfil de redes sociais (Instagram, Facebook, Twitter, Linkedin e YouTube) (R$100,00 mensais)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Realizar publicidade no Google e redes sociais (Instagram, Facebook e YouTube) (R$150,00 mensais)</li>
                        </ul>
                        <div class="item-price"><span class="currency">R$</span>800,00<span class="item-duration">Valo Único</span> </div>
                        <!--<a href="#" class="item-btn btn-fill">Order Now</a>-->
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-600">
                    <div class="pricing-box-layout1">
                        <div class="item-icon color-emerald"><i class="flaticon-aircraft"></i></div>
                        <h3 class="item-title">Construção de Site/Sistema Médio</h3>
                        <ul class="block-list">
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Site feito em HTML5, JavaScript e Angular</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Hospedagem Firebase</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Hospedagem Heroku</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Sistema feito em Java</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Banco de Dados relacional (MySQL, PostgreSQL, Oracle)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Site feito em React</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Criação de Aplicativos iOS e Android</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Funcionalidade de Blog</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Funcionalidade de Orçamento</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Cadastro de usuário</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Realizar pagamento online</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Integração com sistema ERP/PDV</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Manutenção do sistema quando houver necessidade (R$200,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Instalação do Domínio e SSL (R$100,00 anuais)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Criação do Google Meu Negócio e Apple Business (R$100,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Gerenciar perfil de redes sociais (Instagram, Facebook, Twitter, Linkedin e YouTube) (R$100,00 mensais)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Realizar publicidade no Google e redes sociais (Instagram, Facebook e YouTube) (R$150,00 mensais)</li>
                        </ul>
                        <div class="item-price"><span class="currency">R$</span>2.000,00<span class="item-duration">Valo Único</span> </div>
                        <!--<a href="#" class="item-btn btn-fill">Order Now</a>-->
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1100">
                    <div class="pricing-box-layout1">
                        <div class="item-icon color-sunset-orange"><i class="flaticon-plane-1"></i></div>
                        <h3 class="item-title">Construção de Site/Sistema Complexo</h3>
                        <ul class="block-list">
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Site feito em HTML5, JavaScript e Angular</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Hospedagem Firebase</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Hospedagem Heroku</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Sistema feito em Java</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Banco de Dados relacional (MySQL, PostgreSQL, Oracle)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Site feito em React</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Criação de Aplicativos iOS e Android</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Funcionalidade de Blog</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Funcionalidade de Orçamento</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Cadastro de usuário</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Realizar pagamento online</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Integração com sistema ERP/PDV</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Manutenção do sistema quando houver necessidade (R$200,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Instalação do Domínio e SSL (R$100,00 anuais)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Criação do Google Meu Negócio e Apple Business (R$100,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Gerenciar perfil de redes sociais (Instagram, Facebook, Twitter, Linkedin e YouTube) (R$100,00 mensais)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Realizar publicidade no Google e redes sociais (Instagram, Facebook e YouTube) (R$150,00 mensais)</li>
                        </ul>
                        <div class="item-price"><span class="currency">R$</span>5.000,00*<span class="item-duration">Valo Único, *a partir de R$5.000,00</span> </div>
                        <!--<a href="#" class="item-btn btn-fill">Order Now</a>-->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="section-heading heading-dark heading-layout1">
            <div class="heading-sub-title">PREÇOS DE CONSULTORIA</div>
            <h2 class="heading-main-title">Preços de Consultoria Imperdivéis</h2>
            <p class="heading-paragraph">Precisa de um uma Consultoria para o seu negócio? Conte com a gente. Os nossos preços e qualidade vão te surpreender.</p>
        </div>
        <div class="row no-gutters pricing-box-devider pricing-shadow box-bottom-margin has-animation">
            <div class="col-md-4 col-12">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-100">
                    <div class="pricing-box-layout1">
                        <div class="item-icon color-california"><i class="flaticon-origami"></i></div>
                        <h3 class="item-title">Consultoria Empresarial</h3>
                        <ul class="block-list">
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Consultoria Empresarial</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Aconselhamento</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Facilitação de Processo para empresas já conceituadas no mercado (R$500,00)</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Plano de Negócio</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Plano Estratégico</li>
                        </ul>
                        <div class="item-price"><span class="currency">R$</span>0,00<span class="item-duration">Grátis</span> </div>
                        <!--<a href="#" class="item-btn btn-fill">Order Now</a>-->
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-600">
                    <div class="pricing-box-layout1">
                        <div class="item-icon color-emerald"><i class="flaticon-aircraft"></i></div>
                        <h3 class="item-title">Consultoria Plano de Negócio</h3>
                        <ul class="block-list">
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Consultoria Empresarial</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Aconselhamento</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Facilitação de Processo para empresas já conceituadas no mercado (R$500,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Plano de Negócio</li>
                            <li style="font-size: small;"><span style="color: red;">&#10007;</span>Plano Estratégico</li>
                        </ul>
                        <div class="item-price"><span class="currency">R$</span>1.000,00<span class="item-duration">Valo Único</span> </div>
                        <!--<a href="#" class="item-btn btn-fill">Order Now</a>-->
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="translate-bottom-75 opacity-animation transition-150 transition-delay-1100">
                    <div class="pricing-box-layout1">
                        <div class="item-icon color-sunset-orange"><i class="flaticon-plane-1"></i></div>
                        <h3 class="item-title">Consultoria Plano Estratégico</h3>
                        <ul class="block-list">
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Consultoria Empresarial</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Aconselhamento</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Facilitação de Processo para empresas já conceituadas no mercado (R$500,00)</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Plano de Negócio</li>
                            <li style="font-size: small;"><span style="color: blue;">&#10003;</span>Plano Estratégico</li>
                        </ul>
                        <div class="item-price"><span class="currency">R$</span>2.000,00*<span class="item-duration">Valo Único, *a partir de R$2.000,00</span> </div>
                        <!--<a href="#" class="item-btn btn-fill">Order Now</a>-->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="section-heading heading-dark heading-layout1">
            <div class="heading-sub-title">PREÇOS DE MÁQUINA DE CARTÃO</div>
            <h2 class="heading-main-title">Preços de Máquina de Cartão Imperdivéis</h2>
            <p class="heading-paragraph">Precisa de um uma Máquina de Cartão para o seu negócio? Conte com a gente. Somos revendedores SafraPay, conte com a qualidade da Safra, com taxas compatíveis ao mercado.</p>
        </div>
        <div class="row gutters-2">
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="pricing-box-layout2">
                    <div class="item-price"><s><span class="currency">R$</span>189,<span class="super-script">60</span></s></div>
                    <div class="item-price"><span class="currency">R$</span>57,<span class="super-script">60</span></div>
                    <h3 class="item-title">SafraPay MINI</h3>
                    <ul class="block-list">
                        <li>Pix e QR Code</li>
                        <li>Aproximação NFC</li>
                        <li>Bluetooth <s>3G/4G/Wi-Fi</s></li>
                        <li>Comprovante SMS <s>Papel</s></li>
                    </ul>
                    <!--<a href="#" class="item-btn btn-ghost">Order Now</a>-->
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="pricing-box-layout2">
                    <div class="item-price"><s><span class="currency">R$</span>346,<span class="super-script">80</span></s></div>
                    <div class="item-price"><span class="currency">R$</span>177,<span class="super-script">60</span></div>
                    <h3 class="item-title">SafraPay SLIM</h3>
                    <ul class="block-list">
                        <li>Pix e QR Code</li>
                        <li>Aproximação NFC</li>
                        <li>3G e Wi-Fi <s>4G</s></li>
                        <li>Comprovante SMS <s>Papel</s></li>
                    </ul>
                    <!--<a href="#" class="item-btn btn-ghost">Order Now</a>-->
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="pricing-box-layout2">
                    <div class="status-shape"><span class="status-text">Popular</span></div>
                    <div class="item-price"><s><span class="currency">R$</span>562,<span class="super-script">87</span></s></div>
                    <div class="item-price"><span class="currency">R$</span>297,<span class="super-script">60</span></div>
                    <h3 class="item-title">SafraPay PRO</h3>
                    <ul class="block-list">
                        <li>Pix e QR Code</li>
                        <li>Aproximação NFC</li>
                        <li>3G e Wi-Fi <s>4G</s></li>
                        <li>Comprovante SMS e Papel</li>
                    </ul>
                    <!--<a href="#" class="item-btn btn-ghost">Order Now</a>-->
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="pricing-box-layout2">
                    <div class="item-price"><s><span class="currency">R$</span>1.107,<span class="super-script">67</span></s></div>
                    <div class="item-price"><span class="currency">R$</span>887,<span class="super-script">80</span></div>
                    <h3 class="item-title">SafraPay SMART</h3>
                    <ul class="block-list">
                        <li>Pix e QR Code</li>
                        <li>Aproximação NFC</li>
                        <li>4G/3G e Wi-Fi</li>
                        <li>Comprovante SMS e Papel</li>
                    </ul>
                    <!--<a href="#" class="item-btn btn-ghost">Order Now</a>-->
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="section-heading heading-dark heading-layout1">
            <div class="heading-sub-title">PREÇOS DE CERTIFICADO DIGITAL</div>
            <h2 class="heading-main-title">Preços de Certificado Digital Imperdivéis</h2>
            <p class="heading-paragraph">Precisa de um um Certificado Digital? Conte com a gente. Somos um revendedor Serasa Experience, conheça os nossos preços.</p>
        </div>
        <div class="row gutters-2">
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="pricing-box-layout2">
                    <div class="item-price"><span class="currency">R$</span>99,<span class="super-script">90</span></div>
                    <h3 class="item-title">e-Jurídico</h3>
                    <ul class="block-list">
                        <li><s>A1 - Arquivo</s></li>
                        <li>A3 - Cartão</li>
                        <li><s>A3 - Kit Leitora</s></li>
                        <li>A3 - Token</li>
                    </ul>
                    <!--<a href="#" class="item-btn btn-ghost">Order Now</a>-->
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="pricing-box-layout2">
                    <div class="item-price"><span class="currency">R$</span>155,<span class="super-script">00</span></div>
                    <h3 class="item-title">e-CPF</h3>
                    <ul class="block-list">
                        <li>A1 - Arquivo</li>
                        <li>A3 - Cartão</li>
                        <li>A3 - Kit Leitora</li>
                        <li>A3 - Token</li>
                    </ul>
                    <!--<a href="#" class="item-btn btn-ghost">Order Now</a>-->
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="pricing-box-layout2">
                    <div class="item-price"><span class="currency">R$</span>229,<span class="super-script">00</span></div>
                    <h3 class="item-title">e-CNPJ</h3>
                    <ul class="block-list">
                        <li>A1 - Arquivo</li>
                        <li>A3 - Cartão</li>
                        <li>A3 - Kit Leitora</li>
                        <li>A3 - Token</li>
                    </ul>
                    <!--<a href="#" class="item-btn btn-ghost">Order Now</a>-->
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="pricing-box-layout2">
                    <div class="item-price"><span class="currency">R$</span>229,<span class="super-script">00</span></div>
                    <h3 class="item-title">NF-e</h3>
                    <ul class="block-list">
                        <li>A1 - Arquivo</li>
                        <li>A3 - Cartão</li>
                        <li>A3 - Kit Leitora</li>
                        <li>A3 - Token</li>
                    </ul>
                    <!--<a href="#" class="item-btn btn-ghost">Order Now</a>-->
                </div>
            </div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=     Pricing Section Area End      =-->
<!--=====================================-->
<!--=====================================-->
<!--=     Brand Section Area Start      =-->
<!--=====================================-->
<section id="brand-wrap-layout1" class="brand-wrap-layout1 bg-color-accent2">
    <div class="container">
        <div class="rc-carousel" data-loop="true" data-items="8" data-margin="30" data-autoplay="false"
            data-autoplay-timeout="5000" data-smart-speed="700" data-dots="false" data-nav="false"
            data-nav-speed="false" data-r-x-small="2" data-r-x-small-nav="false" data-r-x-small-dots="false"
            data-r-x-medium="3" data-r-x-medium-nav="false" data-r-x-medium-dots="false" data-r-small="4"
            data-r-small-nav="false" data-r-small-dots="false" data-r-medium="4" data-r-medium-nav="false"
            data-r-medium-dots="false" data-r-large="5" data-r-large-nav="false" data-r-large-dots="false"
            data-r-extra-large="6" data-r-extra-large-nav="false" data-r-extra-large-dots="false">
            <div class="brand-box-layout1"><img src="media/brand/brand1.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand2.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand3.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand4.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand5.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand6.png" alt="Brand-thumb"></div>
            <div class="brand-box-layout1"><img src="media/brand/brand3.png" alt="Brand-thumb"></div>
        </div>
    </div>
</section>
<!--=====================================-->
<!--=      Brand Section Area End       =-->
<!--=====================================-->