import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-grafico-redes-sociais',
  templateUrl: './design-grafico-redes-sociais.component.html',
  styleUrls: ['./design-grafico-redes-sociais.component.css']
})
export class DesignGraficoRedesSociaisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
