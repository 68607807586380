import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consultoria-estrategica',
  templateUrl: './consultoria-estrategica.component.html',
  styleUrls: ['./consultoria-estrategica.component.css']
})
export class ConsultoriaEstrategicaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
